import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ToastContainer } from "react-toastify"

const BaseComponents = () => {
  return (
    <>
      <ToastContainer containerId={"toast"} />
      <ReactQueryDevtools initialIsOpen={false} />{" "}
    </>
  )
}

export default BaseComponents
